<template>
  <div>
    <baseDialog :title="formTitle" formName="userForm" :dialogVisible="dialogFormVisible" visibleName="dialogFormVisible" @cancel="cancel($event)" @submit="submitUserForm($event)">
      <el-form :model="userForm" :rules="userRules" ref="userForm">
        <el-form-item label="工号" :label-width="formLabelWidth" prop="userWorkId" class="is-required">
          <el-input v-model="userForm.userWorkId" autocomplete="off" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="realName">
          <el-input v-model="userForm.realName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :label-width="formLabelWidth" prop="mobile">
          <el-input v-model="userForm.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="账户名" :label-width="formLabelWidth" prop="userName">
          <el-input v-model="userForm.userName" autocomplete="off"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="部门" :label-width="formLabelWidth" prop="department">
          <el-select v-model="userForm.department" placeholder="请选择部门">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否在职" :label-width="formLabelWidth">
          <el-switch v-model="userForm.workStatus" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    </baseDialog>
    <baseDialog
      title="修改积分"
      formName="scoreForm"
      :dialogVisible="dialogScoreFormVisible"
      visibleName="dialogScoreFormVisible"
      @cancel="cancel($event)"
      @submit="submitScoreForm($event)"
    >
      <el-form :model="scoreForm" :rules="scoreRules" ref="scoreForm">
        <el-form-item label="当前积分" :label-width="formLabelWidth">
          {{ score }}
        </el-form-item>
        <el-form-item label="奖励积分" :label-width="formLabelWidth" prop="score" class="is-required">
          <el-input v-model="scoreForm.score" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="scoreForm.desc"></el-input>
        </el-form-item>
      </el-form>
    </baseDialog>
  </div>
</template>
<script>
import baseDialog from '@/components/dialog/base-dialog';
export default {
  name: 'userDialog',
  data() {
    const checkWorkId = (rule, value, callback) => {
      const reg = /^[A-Za-z0-9]+$/;
      if (!value) {
        return callback(new Error('请输入工号'));
      }
      if (!reg.test(value)) {
        return callback(new Error('工号为字母和数字组成，长度为20个字符内'));
      } else {
        callback();
      }
    };
    const checkNum = (rule, value, callback) => {
      const reg = /^[0-9]+$/;
      if (!value) {
        return callback(new Error('请输入奖励积分'));
      }
      if (!reg.test(value)) {
        return callback(new Error('请输入正确的积分数值'));
      } else {
        callback();
      }
    };
    return {
      department: '',
      status: '',
      formLabelWidth: '80px',
      userForm: {
        userName: '',
        realName: '',
        mobile: '',
        userWorkId: '',
        workStatus: 1
      },
      userRules: {
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        userWorkId: [{ validator: checkWorkId, trigger: 'blur' }]
      },
      scoreForm: {
        score: '',
        desc: ''
      },
      scoreRules: {
        score: [{ validator: checkNum, trigger: 'blur' }]
      },
      data: [
        {
          id: '12131',
          userName: '李梅梅',
          status: false,
          department: '创新发展部',
          submitNum: '提交量',
          handleNum: '处理量',
          score: '积分',
          time: '时间',
          avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
          phone: '12233334444'
        },
        {
          id: '12132',
          userName: '李梅梅',
          status: true,
          department: '创新发展部',
          submitNum: '提交量',
          handleNum: '处理量',
          score: '积分',
          time: '时间',
          avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
          phone: '12233334444'
        },
        {
          id: '12133',
          userName: '李梅梅',
          status: true,
          department: '创新发展部',
          submitNum: '提交量',
          handleNum: '处理量',
          score: '积分',
          time: '时间',
          avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
          phone: '12233334444'
        }
      ],
      formTitle: '新增人员',
      dialogFormVisible: false,
      dialogScoreFormVisible: false,
      score: ''
    };
  },
  mounted() {},
  methods: {
    init(score) {
      this.score = score;
    },
    //修改积分弹框
    change() {
      this.dialogScoreFormVisible = true;
      // let index = this.search(this.data, id)[0];
      // this.scoreForm = {

      // };
    },
    cancel(formVisible) {
      this[formVisible] = false;
    },
    //修改积分提交
    submitScoreForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogScoreFormVisible = false;
          this.$emit('changeScore', this.scoreForm);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    add() {
      // this.$router.push({ name: 'personal_add' });
      this.dialogFormVisible = true;
      this.formTitle = '新增人员';
      this.userForm = {
        userName: '',
        realName: '',
        mobile: '',
        userWorkId: '',
        workStatus: 1
      };
    },
    //员工新增、修改
    submitUserForm(formName) {
      // this.dialogFormVisible = false;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitForm();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async submitForm() {
      const { resp_code, resp_msg } = await this.$apis.sysUser.add(this.userForm);
      if (resp_code === 1) {
        this.$message({
          type: 'error',
          message: resp_msg
        });
      }
      if (resp_code === 0) {
        this.$message({
          type: 'success',
          message: '添加成功',
          onClose() {
            location.reload();
          }
        });
      }
    },
    // 编辑
    edit(id) {
      this.dialogFormVisible = true;
      this.formTitle = '编辑人员';
      let index = this.search(this.data, id)[0];
      this.userForm = {
        userName: this.data[index].userName,
        department: this.data[index].department,
        phone: this.data[index].phone,
        isOn: this.data[index].status
      };
      // this.$router.push({
      //   name: 'personal_edit',
      //   params: {
      //     id
      //   }
      // });
    },
    //查找对象
    search(object, value) {
      for (let key in object) {
        if (object[key] === value) {
          return [key];
        }
        if (typeof object[key] === 'object') {
          let temp = this.search(object[key], value);
          if (temp) {
            return [key, temp].flat();
          }
        }
      }
    }
  },
  components: {
    baseDialog
  }
};
</script>
<style lang="scss">
</style>>

