import { TABLE } from '/app.config';

const { edit, del, view } = TABLE.icons;

const table = {
  data() {
    return {
      columns: [
        {
          width: 200,
          prop: 'realName',
          label: '员工',
          noUser: false,
          avatar: 'userAvatar',
          other: 'phone'
        },
        {
          width: 200,
          prop: 'userMobile',
          label: '手机号',
          noUser: true
        },
        {
          width: 200,
          prop: 'workStatus',
          dot: true,
          number: true,
          label: '状态',
          noUser: true
        },
        {
          width: 150,
          prop: 'userWorkId',
          label: '工号',
          noUser: true
        },
        {
          width: 130,
          prop: 'orderSubmitNumber',
          label: '提交量',
          noUser: true
        },
        {
          width: 130,
          prop: 'orderHandledNumber',
          label: '处理量',
          noUser: true
        },
        {
          width: 130,
          prop: 'totalPoints',
          label: '积分',
          noUser: true
        },
        {
          width: 200,
          prop: 'createTime',
          time: true,
          label: '时间',
          noUser: true
        }
      ],
      operations: {
        handles: [
          {
            icon: edit,
            name: '复职',
            handle: this.edit
          },
          {
            icon: del,
            name: '离职',
            handle: this.del
          },
          {
            icon: view,
            name: '查看',
            handle: this.view
          }
        ]
      }
    };
  }
};
const score = {
  data() {
    return {
      scoreColumns: [
        {
          width: 200,
          prop: 'pointsDifferenceStr',
          label: '积分变动',
          noUser: true
        },
        {
          width: 200,
          prop: 'operateUserName',
          label: '操作人',
          noUser: true
        },
        {
          width: 400,
          prop: 'content',
          label: '变更说明',
          noUser: true
        },
        {
          width: 822,
          prop: 'updateTime',
          label: '时间',
          noUser: true,
          time: true
        }
      ],
      // hasHandle: false,
      scoreOperations: {}
    };
  }
};

const record = {
  data() {
    return {
      recordColumns: [
        {
          width: 200,
          prop: 'createName',
          label: '发起人',
          noUser: false,
          avatar: 'reporterAvatar',
          other: 'reporterMobile'
        },
        {
          width: 400,
          prop: 'orderInfo',
          label: '事项信息',
          noUser: true,
          hasInfo: true,
          other: ['orderId', 'reportTime', 'title']
        },
        {
          width: 150,
          prop: 'orderClassficationName',
          label: '事项类型',
          noUser: true
        },
        {
          width: 150,
          prop: 'matterClassficationName',
          label: '问题分类',
          noUser: true
        },
        {
          width: 180,
          prop: 'matterPropertyName',
          label: '问题性质',
          noUser: true
        },
        {
          width: 150,
          prop: 'statusName',
          label: '状态',
          dot: true,
          noUser: true
        },
        {
          width: 150,
          prop: 'updateTime',
          time: true,
          label: '处理时间',
          noUser: true
        }
      ],
      recordOperations: {
        handles: [
          {
            icon: view,
            name: '查看',
            handle: this.view
          }
        ]
      }
    };
  }
};
export { table, record, score };
