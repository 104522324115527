<template>
  <div class="main-box">
    <list-container
      :grid="false"
      placeholder="输入搜索内容"
      :searchText.sync="searchText"
      :multipleSelection="multipleSelection"
      :pagination="pagination"
      :uploadFile="uploadFile"
      @titleSearch="titleSearch($event)"
      @add="add"
      @multipleDel="multipleDel"
      @fetch="loadData"
    >
      <template v-slot:filter>
        <div class="select-box">
          <el-select v-model="workStatus" placeholder="请选择员工状态">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-button icon="el-icon-search" style="margin-left: 20px" type="primary" @click="loadData"></el-button>
        </div>
      </template>
      <template v-slot:table>
        <base-table :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" :multi="false" />
      </template>
    </list-container>
    <baseDialog ref="dialog" />
    <msgDialog :title="tipTitle" :tipTxt="tipTxt" :baseDialogVisible="baseDialogVisible" @cancel="baseDialogVisible = false" @submitSure="delSure" />
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import baseTable from '@/components/table/base-table/base-table';
import baseDialog from '@/components/user-dialog/user-dialog';
import msgDialog from '@/components/user-dialog/msg-dialog';
import { TABLE } from '/app.config';
import { table } from './mixin';
export default {
  name: 'personal',
  mixins: [table],
  data() {
    return {
      tipTitle: '',
      tipTxt: '',
      baseDialogVisible: false,
      department: '',
      formLabelWidth: '80px',
      userForm: {
        userName: '',
        phone: '',
        department: '',
        isOn: ''
      },
      userRules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        department: [{ required: true, message: '请选择部门', trigger: 'change' }]
      },
      scoreForm: {
        score: '',
        desc: ''
      },
      scoreRules: {
        score: [{ required: true, message: '请输入积分', trigger: 'blur' }]
      },
      data: [],
      departmentList: [
        {
          value: '1',
          label: '创新发展部'
        }
      ],
      statusList: [
        {
          value: '0',
          label: '离职'
        },
        {
          value: '1',
          label: '在职'
        }
      ],
      workStatus: '',
      formTitle: '新增人员',
      dialogFormVisible: false,
      dialogScoreFormVisible: false,
      searchText: '',
      multipleSelection: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      uploadFile: ['import']
      // uploadFile: []
    };
  },
  activated() {
    this.loadData();
  },
  methods: {
    async loadData({ currentPage, currentPageSize } = {}) {
      this.pagination.currentPage = currentPage || this.pagination.currentPage;
      this.pagination.pageSize = currentPageSize || this.pagination.pageSize;

      const params = {
        realName: this.searchText,
        workStatus: this.workStatus,
        page: this.pagination.currentPage,
        limit: this.pagination.pageSize
      };
      const { data, count } = await this.$apis.sysUser.list(params);
      this.data = data;
      this.pagination.total = count;
    },
    // 新增
    add() {
      this.$refs.dialog.add();
    },
    //修改积分弹框
    change() {
      this.$refs.dialog.change();
    },
    // 删除
    del({ id, workStatus }) {
      this.delId = id;
      this.handleWorkStatus = workStatus;
      this.baseDialogVisible = true;
      this.tipTitle = '删除';
      this.tipTxt = '确定离职? 离职后，人员将不能登录系统使用！';
      // console.log(id);
      // const h = this.$createElement;
      // this.$msgbox({
      //   title: '提示消息',
      //   message: h('p', null, [h('span', null, '确定离职 '), h('p', null, '离职后，人员将不能登录系统使用。')]),
      //   showCancelButton: true,
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   beforeClose: (action, instance, done) => {
      //     if (action === 'confirm') {
      //       instance.confirmButtonLoading = true;
      //       instance.confirmButtonText = '执行中...';
      //       setTimeout(() => {
      //         done();
      //         setTimeout(() => {
      //           instance.confirmButtonLoading = false;
      //         }, 300);
      //       }, 3000);
      //     } else {
      //       done();
      //     }
      //   }
      // }).then(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已离职'
      //   });
      // });
    },
    async delSure() {
      const workStatus = this.handleWorkStatus === 1 ? 0 : 1;
      const tip = this.handleWorkStatus === 1 ? '已离职' : '已复职';
      const { resp_code, resp_msg } = await this.$apis.sysUser.update({
        id: this.delId,
        workStatus
      });
      if (resp_code === 0) {
        this.baseDialogVisible = false;
        this.$message({
          type: 'info',
          message: tip
        });
        this.loadData();
      } else {
        this.$message({
          type: 'info',
          message: resp_msg
        });
      }
    },
    // 编辑
    edit({ id, workStatus }) {
      // this.$refs.dialog.edit(id);
      this.delId = id;
      this.handleWorkStatus = workStatus;
      this.baseDialogVisible = true;
      this.tipTitle = '复职';
      this.tipTxt = '确定复职? 复职后，人员可以正常登录系统使用！';
    },
    // 详情
    view({ id }) {
      this.$router.push({ name: 'personal_detail', params: { id } });
    },
    // 多选删除
    multipleDel() {
      const ids = this.multipleSelection.map(item => item.id);
      this.del({ id: ids.join(',') });
    },
    titleSearch(searchText) {
      this.searchText = searchText;
      // this.searchParams = {};
      this.loadData();
    }
  },
  watch: {
    // searchText() {
    //   this.loadData();
    // }
  },
  components: {
    listContainer,
    baseTable,
    baseDialog,
    msgDialog
  }
};
</script>

<style lang="scss" scoped>
.main-box {
  padding: 20px;
  background: #fff;
  border-radius: 6px;
}
.select-box {
  margin-left: 20px;
}
</style>
